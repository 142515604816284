<template>
  <div class="position-relative w-100 h-100">
    <BCIcon class="bc-icon" :style="{ fill: iconColor }" />
    <div class="books-background__books d-flex flex-wrap">
      <div
        v-for="book in books"
        :key="book.title"
        class="books-background__book slide-in-up"
      >
        <img
          :src="`${book.cover}&width=336&height=516&fit=cover`"
          class="books-background__book__cover"
          :alt="book.title"
          :width="168"
          :height="258"
          fetchpriority="high"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BCIcon from '~/assets/icons/bc-icon.svg'
import { transformMonthFromReadable } from '~/utils/monthFormatter'

export default {
  components: {
    BCIcon,
  },
  props: {
    iconColor: {
      type: String,
      default: '#5BC4A6',
    },
  },
  computed: {
    ...mapGetters({
      categories: 'books/categories',
      getBooksForMonth: 'books/getBooksForMonth',
    }),
    books() {
      return this.getBooksForMonth(this.currentMonth)
    },
    currentMonth() {
      const categories = this.categories([0])
      const { value } = categories.pop()
      const month = value
      return transformMonthFromReadable(month)
    },
  },
}
</script>

<style lang="scss" scoped>
.bc-icon {
  position: absolute;

  width: 500vw;
  left: -316vw;
  bottom: -255vw;
  transform: rotate(296deg);
}

.books-background__books {
  position: absolute;
  z-index: 1;
  width: 112vw;
  min-width: 300px;
  justify-content: space-evenly;
  gap: 3vw;
  transform: rotate(12.2deg) translateX(-50%);
  bottom: 16px;
  left: 48%;
}

.books-background__book {
  flex-basis: 22.8%;

  &:nth-child(2),
  &:nth-child(4) {
    margin-top: 16px;
  }
  &:nth-child(5),
  &:nth-child(7) {
    margin-top: -16px;
  }
}
@for $i from 1 through 8 {
  .books-background__book:nth-child(#{$i}n) {
    transform: translateY(110vh);
    animation: slideInFromBottom 0.5s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}
.books-background__book__cover {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0px 0.6px 2.38px 0px rgba(0, 0, 0, 0.25);
}

@include media-breakpoint-up(sm) {
  .books-background__books {
    width: 73vw;
    z-index: 0;
    left: 0;
    top: 50%;
    left: 50%;
    bottom: unset;
    gap: 10px;
    transform: translate(-50%, -50%) rotate(10deg);
  }
}

@include media-breakpoint-up(md) {
  .books-background__book__cover {
    border-radius: 8px;
    box-shadow: 0px 1.01px 4.03px 0px rgba(0, 0, 0, 0.25);
  }
}

@include media-breakpoint-up(lg) {
  .bc-icon {
    width: 231vw;
    left: -50vw;
    bottom: unset;
    top: -81vw;
    transform: rotate(131deg);
  }
  .books-background__books {
    transform: rotate(-10deg);
    width: 545px;
    top: 15%;
    left: 53%;
  }

  .books-background__book__cover {
    width: 120px;
  }

  .books-background__book {
    &:nth-child(2),
    &:nth-child(4) {
      margin-top: 24px;
    }
    &:nth-child(5),
    &:nth-child(7) {
      margin-top: -24px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .books-background__books {
    width: 728px;
    top: 10%;
    left: 53%;
    gap: 16px;
  }

  .books-background__book__cover {
    width: 166px;
  }
}
</style>
